@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, "Rubik", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 8px;
  min-height: 100px;
}

*::-webkit-scrollbar-thumb:hover {
  background: gray;
}

*::-webkit-scrollbar:hover {
  background: #8080803b;
}

#mentionsPost:focus-visible {
  outline: none;
  transition: border 0.5s linear;
  border-bottom: 3px solid #26A65B !important;
}

#mentionsComment:focus-visible {
  outline: none;
  border: 1px solid #26A65B !important;
}
