/* Filepicker styles CSS */
div.filepicker {
    background-color: rgba(34, 112, 168, 0.04);
    border: 1px dashed #2270a870;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.dropzone .dz-preview.dz-image-preview {
    background-color: transparent !important;
}